import { ReactNode } from 'react'
import Link from 'next/link'

import { OldRowLogo } from '@ui/icons'

function SiteLayout({ children }: { children: ReactNode }) {
  return (
    <div className='relative py-0 bg-lightgray text-gray'>
      <nav className='sticky top-0 left-0 z-10 flex justify-center w-full py-3 bg-white shadow-sm'>
        <Link href='/'>
          <a>
            <div className='w-[110px]'>
              <OldRowLogo />
            </div>
          </a>
        </Link>
      </nav>
      <main className='flex flex-col items-center justify-start w-full min-h-screen px-4 py-8'>
        {children}
      </main>
      <footer className='px-0 py-4 text-center'>
        Copyright © {new Date().getFullYear()} Old Row. All Rights Reserved. <br />
        <Link href='https://www.barstoolsports.com/terms-of-use'>
          <a className='text-[#1A78BD]' target='_blank' rel='noreferrer'>
            Terms of Use
          </a>
        </Link>{' '}
        |{' '}
        <Link href='https://www.barstoolsports.com/privacy-policy'>
          <a className='text-[#1A78BD]' target='_blank' rel='noreferrer'>
            Privacy Policy
          </a>
        </Link>{' '}
        |{' '}
        <Link href='https://www.barstoolsports.com/content-policy'>
          <a className='text-[#1A78BD]' target='_blank' rel='noreferrer'>
            Content Policy
          </a>
        </Link>
      </footer>
    </div>
  )
}

export default SiteLayout
