import { ReactNode } from 'react'

export interface FormControlProps {
  label?: string
  name?: string
  error?: string
  children?: ReactNode
}

function FormControl({ label, name, error, children }: FormControlProps) {
  return (
    <div className='space-y-1 w-full'>
      {label && (
        <label className='text-sm font-bold' htmlFor={name}>
          {label}
        </label>
      )}
      <div>{children}</div>
      {error && <p className='text-sm text-red-500'>* {error}</p>}
    </div>
  )
}

export default FormControl
