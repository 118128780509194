import classNames from 'classnames'
import React from 'react'

export interface InputAreaProps extends Omit<React.HTMLProps<HTMLTextAreaElement>, 'data'> {}

function InputArea({
  className,
  name,
  rows,
  value,
  disabled,
  onChange,
  onBlur,
  onFocus,
  onClick,
  onCopy,
  onCut,
  onPaste,
  onKeyDown,
  onKeyUp,
  onKeyPress
}: InputAreaProps) {
  return (
    <div className='w-full'>
      <textarea
        rows={rows ?? 4}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onClick={onClick}
        onCopy={onCopy}
        onCut={onCut}
        onPaste={onPaste}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        onKeyUp={onKeyUp}
        id={name}
        className={classNames(
          'w-full rounded-sm shadow-inner p-2 border border-[#afafaf] outline-blue',
          className
        )}
      />
    </div>
  )
}

export default InputArea
