import { Field, FieldRenderProps, FieldProps } from 'react-final-form'

import Checkbox, { CheckboxProps } from './checkbox'
import FormControl, { FormControlProps } from './form-control'
import Input, { InputProps } from './input'
import InputArea, { InputAreaProps } from './inputarea'
import MediaUpload, { MediaUploadProps } from './media-upload'

type TextFieldProps = FormControlProps &
  InputProps &
  FieldProps<string, FieldRenderProps<string, HTMLElement, string>>

export function TextField(props: TextFieldProps) {
  return (
    <Field<string>
      {...props}
      render={(fieldProps) => {
        const { meta, input, ...rest } = fieldProps
        return (
          <FormControl
            label={props.label}
            name={props.name}
            error={fieldProps.meta.touched ? fieldProps.meta.error : undefined}
          >
            <Input {...meta} {...input} {...rest} />
          </FormControl>
        )
      }}
    />
  )
}

type TextAreaFieldProps = FormControlProps &
  InputAreaProps &
  FieldProps<string, FieldRenderProps<string, HTMLElement, string>>

export function TextAreaField(props: TextAreaFieldProps) {
  return (
    <Field<string>
      {...props}
      render={(fieldProps) => {
        const { meta, input, ...rest } = fieldProps
        return (
          <FormControl
            label={props.label}
            name={props.name}
            error={fieldProps.meta.touched ? fieldProps.meta.error : undefined}
          >
            <InputArea {...meta} {...input} {...rest} />
          </FormControl>
        )
      }}
    />
  )
}

type CheckboxFieldProps = Omit<FormControlProps, 'label'> &
  CheckboxProps &
  FieldProps<boolean, FieldRenderProps<boolean, HTMLElement, boolean>>

export function CheckboxField(props: CheckboxFieldProps) {
  return (
    <Field<boolean>
      {...props}
      render={(fieldProps) => {
        const { meta, input, ...rest } = fieldProps
        return (
          <FormControl
            name={props.name}
            error={fieldProps.meta.touched ? fieldProps.meta.error : undefined}
          >
            <Checkbox {...meta} {...input} {...rest} />
          </FormControl>
        )
      }}
    />
  )
}

type MediaUploadFieldProps = FormControlProps &
  MediaUploadProps &
  FieldProps<string, FieldRenderProps<string, HTMLElement, string>>

export function MediaUploadField(props: MediaUploadFieldProps) {
  return (
    <Field<string>
      {...props}
      render={(fieldProps) => {
        const { meta, input, ...rest } = fieldProps
        return (
          <FormControl error={fieldProps.meta.touched ? fieldProps.meta.error : undefined}>
            <MediaUpload {...meta} {...input} {...rest} />
          </FormControl>
        )
      }}
    />
  )
}
