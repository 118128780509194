import { LoadingIcon } from '@ui/icons'
import React from 'react'

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset' | undefined
  loading?: boolean
}

function Button({ type, loading, children, onClick, disabled, onFocus, onBlur }: ButtonProps) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      onFocus={onFocus}
      onBlur={onBlur}
      type={type ?? 'button'}
      className='w-full py-2 text-lg text-white rounded-md bg-crimson'
    >
      {loading ? (
        <div className='flex items-center justify-center w-full'>
          <div className='inline-block animate-spin'>
            <LoadingIcon size='24px' />
          </div>
        </div>
      ) : (
        children
      )}
    </button>
  )
}

export default Button
