import React from 'react'

export function ProgressBar({ progress }: { progress: number }) {
  return (
    <div>
      <div className='w-full h-2 overflow-hidden bg-gray-200 rounded-full'>
        <div
          className='w-full h-2 bg-blue rounded-full m-w-fit transition-width duration-200 ease-out'
          style={{ width: `${progress * 100}%` }}
        />
      </div>
    </div>
  )
}

export default ProgressBar
