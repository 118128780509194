import isNil from 'lodash/isNil'
import isFinite from 'lodash/isFinite'
import isEmail from 'validator/lib/isEmail'
import { ValidatablePayload, Validation } from './types'

export function email(msg: string = 'Field must be a valid email'): Validation<string | undefined> {
  return (val) => new Promise((resolve) => resolve(val && isEmail(val) ? undefined : msg))
}

export function numeric(
  msg: string = 'Field must be a finite number value'
): Validation<string | number | undefined | null> {
  return (val) =>
    new Promise((resolve) =>
      !isFinite(Number(val)) || isNil(val) ? resolve(msg) : resolve(undefined)
    )
}

export function truthy(msg: string = 'Field must be true'): Validation<any> {
  return (val) => new Promise((resolve) => (!!val ? resolve(undefined) : resolve(msg)))
}

export function falsy(msg: string = 'Field must be false'): Validation<any> {
  return (val) => new Promise((resolve) => (!val ? resolve(undefined) : resolve(msg)))
}

export function every(
  predicate: (arg: any) => boolean,
  msg: string = 'Each value must be complete'
): Validation<Array<any> | undefined> {
  return (val) =>
    new Promise((resolve) => (val?.every(predicate) ? resolve(undefined) : resolve(msg)))
}

export function when(
  predicate: (formValues?: ValidatablePayload) => boolean,
  validation: Validation<any> | Validation<any>[]
): Validation<any> | Validation<any>[] | undefined {
  return (val, formValues) =>
    new Promise(async (resolve) => {
      if (!predicate(formValues)) resolve(undefined)
      const validations = Array.isArray(validation) ? validation : [validation]
      const results = await Promise.all(
        validations.map((validationPiece) => validationPiece(val, formValues))
      )
      resolve(results.find((result) => result !== undefined))
    })
}
