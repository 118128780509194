import { RefObject } from 'react'
import { parallelLimit } from 'async'
import { retriable } from '@lib/utils'
import { HttpMethod, MediaMultipartData } from '@api/types'

const MEGABYTE = 1_048_576

export interface ChunkUploaderOptions {
  file: File
  endpoints: (args: any) => Promise<any>
  cancelRef: React.RefObject<any>
  onUploadProgress: (progress: number) => void
}

export class ChunkUploader {
  readonly chunkSize: number = 8
  readonly maxAttempts: number = 5
  readonly maxConcurrency: number = 4
  readonly retryDelay: number = 2000
  readonly chunkByteSize: number
  readonly totalChunks: number
  readonly total: number
  readonly progress: number
  totalUrls: number = 0
  completedUrls: number = 0

  file: File
  reader: FileReader
  eventTarget: EventTarget
  endpoints: (args: any) => Promise<any>
  onUploadProgress: (progress: number) => void
  cancelRef: RefObject<any>
  uploadProgressByUrl!: Record<string, number>

  constructor(options: ChunkUploaderOptions) {
    this.file = options.file
    this.endpoints = options.endpoints
    this.cancelRef = options.cancelRef
    this.chunkByteSize = this.chunkSize * MEGABYTE
    this.totalChunks = Math.ceil(this.file.size / this.chunkByteSize)
    this.reader = new FileReader()
    this.eventTarget = new EventTarget()
    this.progress = this.total = this.file.size
    this.onUploadProgress = options.onUploadProgress
  }

  async upload(): Promise<MediaMultipartData> {
    const {
      bucket,
      key,
      upload_id,
      urls
    }: { bucket: string; key: string; upload_id: string; urls: string[] } =
      await this.getEndpoints()

    this.uploadProgressByUrl = urls.reduce((acc: { [key: string]: number }, url: string) => {
      acc[url] = 0
      return acc
    }, {})

    this.totalUrls = urls.length
    const etags = (await this.sendChunks(urls)) as string[]
    return { key, bucket, upload_id, etags }
  }

  /**
   * Subscribe to an event
   */
  on(eventName: string, fn: () => void) {
    this.eventTarget.addEventListener(eventName, fn)
  }

  /**
   * Dispatch an event
   */
  dispatch(eventName: string, detail: any) {
    const event = new CustomEvent(eventName, { detail })
    this.eventTarget.dispatchEvent(event)
  }

  private async getEndpoints() {
    return this.endpoints({ file: this.file, parts: this.totalChunks })
  }

  /**
   * Get portion of the file of x bytes corresponding to chunkSize
   */
  private async getChunk(index: number) {
    return this.file.slice(index * this.chunkByteSize, (index + 1) * this.chunkByteSize)
  }

  /**
   * Sends all chunks in serial
   */
  private async sendChunks(urls: string[]): Promise<any> {
    return parallelLimit(
      urls.map((url, index) => (callback: any) => {
        this.getChunk(index)
          .then((chunk) => this.sendChunk(url, chunk))
          .then((res: any) => res.headers.get('etag').replace(/"/g, ''))
          .then((etag) => callback(null, etag))
          .catch(callback)
      }),
      this.maxConcurrency
    )
  }

  /**
   * Send chunk of the file with appropriate headers and add post parameters if it's last chunk
   */
  private async sendChunk(url: string, chunk: Blob) {
    return retriable<Response | null>(
      async () => {
        if (this.cancelRef?.current) {
          return null
        }
        return this.request({
          url: url,
          headers: { 'Content-Type': chunk.type },
          method: 'put',
          data: chunk
        })
      },
      this.maxAttempts,
      this.retryDelay
    )
  }

  private async request({
    url,
    method,
    data,
    headers
  }: {
    url: string
    method: HttpMethod
    data: BodyInit
    headers?: HeadersInit
  }): Promise<Response> {
    const res = await fetch(url, {
      method: method.toUpperCase(),
      body: data,
      headers
    })
    this.completedUrls = (this.completedUrls ?? 0) + 1
    this.onUploadProgress(this.completedUrls / this.totalUrls)
    return res
  }
}
