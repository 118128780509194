import set from 'lodash/set'
import get from 'lodash/get'
import pick from 'lodash/pick'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import {
  PayloadValidation,
  PayloadValidationResult,
  ValidatablePayload,
  Validation,
  ValidationRunners,
  ValidationSchema
} from './types'

function composeValidations<V extends ValidatablePayload>(
  schema: ValidationSchema<V>
): Partial<ValidationRunners<V>> {
  // compose validations for each key in the schema
  const propertyValidations: Partial<ValidationRunners<V>> = {}
  for (const property in schema) {
    propertyValidations[property] = async (value, otherVals) => {
      // no validations to run, valid by default
      const _validations = schema[property]
      if (!_validations) return

      const validations = Array.isArray(_validations) ? _validations : [_validations]
      const validationResults = await Promise.all(
        validations.map((validation) => validation(value, otherVals))
      )
      const fieldError = validationResults.find((result) => result !== undefined)
      return fieldError
    }
  }

  return propertyValidations
}

export function composeValidation<V extends ValidatablePayload>(
  schema: ValidationSchema<V>
): PayloadValidation<V> {
  // compose validations for each key in the schema
  const propertyValidations = composeValidations<V>(schema)

  // compose the validation to run on the entire payload shape
  const payloadValidator: PayloadValidation<V> = async (
    values: Partial<V>,
    pickFields?: Partial<keyof V>[]
  ) => {
    const validations = pickFields ? pick(propertyValidations, pickFields) : propertyValidations
    const validationResults: PayloadValidationResult<V> = {}
    const properties: (keyof V)[] = Object.keys(validations)
    await Promise.all(
      properties.map(async (property) => {
        const value = get(values, property)
        const validation = validations[property]
        const validationResult = await validation?.(value, values)
        if (validationResult) set(validationResults, property, validationResult)
      })
    )
    if (Object.keys(validationResults).length > 0) return validationResults
    return undefined
  }

  return payloadValidator
}

export function exists(msg: string = 'Field must be defined'): Validation<any> {
  return (val) => new Promise((resolve) => (isNil(val) ? resolve(msg) : resolve(undefined)))
}

export function nonempty(
  msg: string = 'Field must not be empty'
): Validation<string | object | Set<any> | any[] | undefined | null> {
  return (val) =>
    new Promise((resolve) => {
      const sanitizedValue = typeof val === 'string' ? val.trim() : val
      isEmpty(sanitizedValue) ? resolve(msg) : resolve(undefined)
    })
}
