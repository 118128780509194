import React, { useRef, FocusEvent, ChangeEvent, DragEvent, ReactNode } from 'react'
import mime from 'mime-types'
import last from 'lodash/last'
import { ChunkUploader } from '@lib/uploader'
import { mediaUploadService } from '@api/media-upload'

interface FileUploaderProps {
  id?: string
  name: string
  type?: string
  accept?: string
  label?: ReactNode
  className?: string
  onBlur?: () => void
  onChange: (url: string | string[]) => void
  onError: (msg: string | null) => void
  onProgress: (progress: number) => void
}

export function FileUploader({
  id,
  name,
  type = 'file',
  accept = '*',
  label,
  className,
  onChange,
  onBlur,
  onError,
  onProgress
}: FileUploaderProps) {
  const cancelUploadRef = useRef(false)

  const uploadFile = async (file: File): Promise<string> => {
    const acceptedTypes = accept.split(',').map((acceptType) => acceptType.trim())
    if (!acceptedTypes.includes(file.type)) {
      const acceptedExtensions = acceptedTypes.map((contentType) => mime.extension(contentType))
      throw new Error(`File type must be: ${acceptedExtensions.join(', ')}`)
    }
    onProgress(0.01)
    const fileType = file.type
    const filename = file.name
    const extension = last(filename.split('.'))

    const uploader = new ChunkUploader({
      file,
      cancelRef: cancelUploadRef,
      endpoints: ({ parts }: { parts: number }) =>
        mediaUploadService.beginMultipartUpload({
          extension,
          content_type: fileType,
          filename,
          parts
        }),
      onUploadProgress: onProgress
    })

    const multipartData = await uploader.upload()
    const mediaUpload = await mediaUploadService.completeMultipartUpload(multipartData)
    return mediaUpload.location
  }

  const onUploadError = (uploadError: string) => {
    onError(uploadError)
    onProgress(0)
  }

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    const file = target?.files?.[0]
    if (!file) return
    try {
      const url = await uploadFile(file)
      onChange(url)
      onError(null)
      onBlur?.()
    } catch (err: any) {
      onUploadError(err?.message || err || 'An Error Occurred')
    } finally {
      setTimeout(() => onProgress(0), 300)
    }
  }

  const handleDrop = async (event: DragEvent<HTMLInputElement | HTMLLabelElement>) => {
    event.preventDefault()
    const files: File[] = Array.from(event.dataTransfer.files)
    try {
      const url: string = await uploadFile(files[0])
      onChange(url)
      onError(null)
      onBlur?.()
    } catch (err: any) {
      onUploadError(err?.message || err || 'An Error Occurred')
    } finally {
      setTimeout(() => onProgress(0), 300)
    }
  }

  const preventDefault = (e: DragEvent<HTMLInputElement | HTMLLabelElement>) => e.preventDefault()

  return (
    <>
      {label ? (
        <label
          htmlFor={id}
          className={className}
          onDragOver={preventDefault}
          onDragEnter={preventDefault}
          onDragLeave={preventDefault}
          onDrop={handleDrop}
        >
          <input
            id={id}
            type={type}
            accept={accept}
            name={name}
            className='hidden w-full h-full'
            onChange={handleChange}
            onBlur={onBlur}
          />
          {label}
        </label>
      ) : (
        <input
          id={id}
          type={type}
          accept={accept}
          name={name}
          className='hidden w-full h-full'
          onChange={handleChange}
          onDragOver={preventDefault}
          onDragEnter={preventDefault}
          onDragLeave={preventDefault}
          onDrop={handleDrop}
          onBlur={onBlur}
        />
      )}
    </>
  )
}

export default FileUploader
