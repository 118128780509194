import React, { createRef, ReactNode } from 'react'

export interface CheckboxProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'onChange' | 'data' | 'label'> {
  value?: boolean
  label?: ReactNode
  name?: string
  required?: boolean
  onChange?: (val: boolean) => void
}

function Checkbox({
  name,
  value,
  required,
  label,
  disabled,
  onChange,
  onBlur,
  onFocus,
  onClick,
  onCopy,
  onCut,
  onPaste,
  onKeyDown,
  onKeyUp,
  onKeyPress
}: CheckboxProps) {
  const ref = createRef<HTMLInputElement>()
  const handleChange = async () => {
    await onChange?.(!value)
    ref.current?.blur?.()
  }
  return (
    <div className='flex form-check'>
      <input
        className='form-check-input shrink-0 appearance-none h-4 w-4 border border-gray rounded-sm bg-white checked:bg-crimson checked:border-crimson focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer after:w-1.5 after:h-2.5 after:rotate-45 after:border-b-2 after:border-r-2 after:border-white after:translate-x-1 after:hidden checked:after:block'
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        onClick={onClick}
        onCopy={onCopy}
        onCut={onCut}
        onPaste={onPaste}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        onKeyUp={onKeyUp}
        onChange={handleChange}
        type='checkbox'
        value={!!value ? 'true' : ''}
        checked={value}
        id={name}
        ref={ref}
      />
      <label className='inline-block text-gray-800 form-check-label' htmlFor={name}>
        {required && <span className='text-red-600'>* </span>}
        {label}
      </label>
    </div>
  )
}

export default Checkbox
