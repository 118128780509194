import { UnionService } from './common'
import { MediaUpload, MediaMetaData, MediaMultipartMetaData, MediaMultipartData } from './types'

class MediaUploadService extends UnionService<MediaUpload> {
  constructor() {
    super('media')
  }

  async beginMultipartUpload(data: MediaMultipartMetaData): Promise<MediaMultipartData> {
    const response = await this.post<MediaMultipartData>(`${this.resource}/upload-multipart`, data)
    return response
  }

  async completeMultipartUpload(data: MediaMultipartData): Promise<MediaUpload> {
    const response = await this.post<MediaUpload>(
      `${this.resource}/upload-multipart/complete`,
      data
    )
    return response
  }
}

export const mediaUploadService = new MediaUploadService()
