import type { NextPage } from 'next'
import Head from 'next/head'

import SiteLayout from '@ui/components/site-layout'
import VideoSubmissionForm from '@ui/components/video-submission-form'

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>Old Row</title>
        <meta name='description' content='Send us your best videos | Old Row' />
        <link rel='icon' href='/favicon.ico' />
      </Head>
      <SiteLayout>
        <VideoSubmissionForm />
      </SiteLayout>
    </>
  )
}

export default Home
