import { AnyKeys, HttpBody, HttpParams, HttpMethod } from './types'

export class Service<Model> {
  baseUrl: string
  resource: string

  constructor(baseUrl: string, resource: string) {
    this.baseUrl = baseUrl
    this.resource = resource
  }

  async get<T>(path: string, params?: HttpParams): Promise<T> {
    return this.request({ path, method: 'get', params })
  }

  async delete<T>(path: string, params?: HttpParams): Promise<T> {
    return this.request({ path, method: 'delete', params })
  }

  async post<T>(path: string, body?: HttpBody): Promise<T> {
    return this.request({ path, method: 'post', body })
  }

  async put<T>(path: string, body?: HttpBody): Promise<T> {
    return this.request({ path, method: 'put', body })
  }

  async patch<T>(path: string, body: HttpBody): Promise<T> {
    return this.request({ path, method: 'patch', body })
  }

  async create(body: AnyKeys<Model>): Promise<Model> {
    const response = await this.post<Model>(`${this.resource}`, body)
    return response
  }

  async request<T>(props: {
    path: string
    method: HttpMethod
    params?: HttpParams
    body?: HttpBody
  }): Promise<T> {
    let params = undefined
    let body = undefined

    switch (props.method) {
      case 'get':
      case 'delete':
      case 'options': {
        params = props.params
          ? Object.keys(props.params)
              .sort()
              .filter(
                (key: string) => props.params?.[key] !== undefined && props.params?.[key] !== null
              )
              .map((key: string) => [
                encodeURIComponent(key),
                encodeURIComponent(props.params?.[key] as string | number | boolean)
              ])
              .map(([key, value]) => `${key}=${value}`)
              .join('&')
          : ''
        break
      }
      case 'post':
      case 'put':
      case 'patch': {
        body = JSON.stringify(props.body ?? {})
        break
      }
      default:
        throw new Error('Invalid request method')
    }

    const path = [props.path, params].filter(Boolean).join('?')

    const res = await fetch(`${this.baseUrl}/${path}`, {
      method: props.method.toUpperCase(),
      body,
      headers: {
        'content-type': 'application/json'
      }
    })

    // Parse json response
    const json = await res.json()

    // Throw response as an error if we did not receive a 200
    if (!res.ok) {
      throw new Error(json.message)
    }

    return json
  }
}

export class UnionService<Model> extends Service<Model> {
  constructor(resource: string) {
    super(process.env.NEXT_PUBLIC_UNION_API as string, resource)
  }
}
