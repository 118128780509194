import { useState, FocusEvent } from 'react'
import classNames from 'classnames'

import { ProgressBar } from '@ui/elements/progress-bar'
import FileUploader from '@ui/elements/file-uploader'
import { CheckCircle, UploadIcon } from '@ui/icons'

export interface MediaUploadProps {
  name: string
  value?: string
  error?: string
  label?: string
  accept?: string
  onBlur?: () => void
  onChange?: (url: string | string[]) => void
}

function MediaUpload({
  name,
  value,
  label = 'Click or drag to upload',
  accept = 'image/jpeg, image/gif, image/png, video/quicktime, video/mp4, video/mpeg',
  onBlur,
  onChange = () => {}
}: MediaUploadProps) {
  const [error, setError] = useState<string | null>(null)
  const [progress, setProgress] = useState<number>(0)
  const isUploading = progress > 0 && progress < 1

  return (
    <FileUploader
      className='w-full'
      name={name}
      accept={accept}
      label={
        <div className='relative cursor-pointer w-full h-32 p-8 rounded-lg border-2 border-dashed border-[#afafaf] bg-white flex flex-col justify-center items-center'>
          <div className='flex justify-center items-center'>
            {value ? (
              <>
                <span className='pr-4'>{value.split('/').pop()}</span>{' '}
                <CheckCircle className='text-green-600' />
              </>
            ) : (
              <>
                <UploadIcon size='24px' />
                <span className='text-lg pl-2'>{label}</span>
              </>
            )}
          </div>
          <div
            className={classNames(
              'absolute top-0 bottom-0 w-full transition-opacity ease-out duration-300',
              { 'opacity-0': !isUploading, 'opacity-100': isUploading }
            )}
          >
            <div className='h-full w-full bg-gray-700 bg-opacity-60' />
            <div className='absolute bottom-1/4 w-full px-8'>
              <ProgressBar progress={progress} />
            </div>
          </div>
          {error && <p className='text-sm text-red-600'>Upload Error: {error}</p>}
        </div>
      }
      onBlur={onBlur}
      onChange={onChange}
      onError={setError}
      onProgress={setProgress}
    />
  )
}

export default MediaUpload
