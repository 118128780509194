export async function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export const retriable = async <T>(
  task: () => T | Promise<T>,
  maxAttempts = 5,
  retryDelay = 2000,
  attempt = 0
): Promise<T> => {
  // Delay request if second attempt
  await delay(attempt * retryDelay)

  try {
    return await task()
  } catch (err: any) {
    // Re-throw error if we receive a 4xx
    if ((err.status && err.status < 500) || (err.response && err.response.status < 500)) {
      throw err
    }
    // Re-throw error if we reached max number of attempts
    if (attempt > maxAttempts) {
      throw err
    }
    // Retry task
    return retriable(task, maxAttempts, retryDelay, attempt + 1)
  }
}
