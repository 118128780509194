import { UnionService } from './common'
import { ViralSubmission } from './types'

class ViralService extends UnionService<ViralSubmission> {
  constructor() {
    super('viral/posts')
  }
}

export const viralService = new ViralService()
