import { useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import Link from 'next/link'

import { viralService } from '@api/viral'
import { TextField, CheckboxField, TextAreaField, MediaUploadField } from '@ui/elements/form-fields'
import Button from '@ui/elements/button'
import { composeValidation, nonempty, truthy, email } from '@lib/validation'
import lowerCase from 'lodash/lowerCase'

type VideoSubmission = {
  name?: string
  email?: string
  instagram?: string
  twitter?: string
  tiktok?: string
  description?: string
  video?: string
  policyAgreement?: boolean
  anonymous?: boolean
  adultAge?: boolean
  noExclusiveAgreements?: boolean
  physicallyRecordedVideo?: boolean
  signature?: string
}

const initialValues: VideoSubmission = {
  name: '',
  email: '',
  instagram: '',
  twitter: '',
  tiktok: '',
  description: '',
  video: undefined,
  policyAgreement: false,
  anonymous: false,
  adultAge: false,
  noExclusiveAgreements: false,
  physicallyRecordedVideo: false,
  signature: ''
}

function VideoSubmissionForm() {
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = async (vals: VideoSubmission) => {
    setSubmitting(true)
    try {
      const { name, email, instagram, twitter, tiktok } = vals
      const key = (vals?.video?.split('.com/') ?? [])[1]
      const attribution = { name, email, instagram, twitter, tiktok }
      await viralService.create({ key, attribution, source: 'oldrow' })
      setSubmitted(true)
      window.scrollTo?.(0, 0)
    } catch (err) {
      console.error(err)
    } finally {
      setSubmitting(false)
    }
  }

  const validation = useMemo(
    () =>
      composeValidation<VideoSubmission>({
        name: nonempty('Name is required'),
        email: [nonempty('Email is required'), email('Must be a valid email format')],
        video: nonempty('Upload is required'),
        policyAgreement: truthy('Must agree to the Content Policy and Privacy Policy to submit'),
        adultAge: truthy('Must be over the age of 18 to submit'),
        noExclusiveAgreements: truthy('Cannot submit this video if you have exclusive agreements'),
        physicallyRecordedVideo: truthy(
          'Cannot submit this video unless you physically recorded it yourself'
        ),
        signature: [
          nonempty('Please provide a digital signature'),
          // custom validation to ensure the signature is the same as the name
          (sig, values) =>
            new Promise((resolve) =>
              lowerCase(sig?.trim()) === lowerCase(values?.name?.trim())
                ? resolve(undefined)
                : resolve('Signature does not match the name above')
            )
        ]
      }),
    []
  )

  return (
    <div className='flex flex-col items-center justify-start w-full text-center'>
      {!submitted ? (
        <>
          <h1 className='my-6 text-3xl font-medium uppercase'>Send us your best videos</h1>
          <p>Fill out the form below. We will keep it anonymous.</p>
          <Form
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validate={validation}
            validateOnChange
            validateOnBlur
          >
            {({ handleSubmit, errors, submitFailed }) => (
              <form
                onSubmit={handleSubmit}
                className='w-full max-w-[650px] mx-auto text-left py-6 space-y-4'
              >
                <TextField name='name' label='Name' placeholder='John Doe' />
                <TextField
                  name='email'
                  type='email'
                  label='Email'
                  placeholder='example@email.com'
                />
                <TextField name='instagram' label='Instagram' placeholder='@johndoe' />
                <TextField name='twitter' label='Twitter' placeholder='@johndoe' />
                <TextField name='tiktok' label='Tiktok' placeholder='@johndoe' />
                <TextAreaField name='description' label='Description' />
                <MediaUploadField name='video' label='Upload content.' />
                <CheckboxField
                  name='policyAgreement'
                  required
                  label={
                    <>
                      I agree to the{' '}
                      <Link href='https://www.barstoolsports.com/content-policy'>
                        <a className='text-[#1A78BD]' target='_blank' rel='noreferrer'>
                          Content Policy
                        </a>
                      </Link>{' '}
                      and{' '}
                      <Link href='https://www.barstoolsports.com/privacy-policy'>
                        <a className='text-[#1A78BD]' target='_blank' rel='noreferrer'>
                          Privacy Policy
                        </a>
                      </Link>
                    </>
                  }
                />
                <CheckboxField name='adultAge' label='I am over the age of 18' required />
                <CheckboxField
                  name='noExclusiveAgreements'
                  label='I have not signed any exclusive agreements with anyone else, or any other website(s)'
                  required
                />
                <CheckboxField
                  name='physicallyRecordedVideo'
                  label='I physically recorded this video'
                  required
                />
                <CheckboxField
                  name='alcoholAgeMinimum'
                  required
                  label="I've confirmed that everyone in the content, including myself, is over the age of 21 (if my content shows any alcohol products or branding or any other 21+ products or branding)"
                />
                <CheckboxField
                  name='anonymous'
                  label='I want my name/social account to remain anonymous'
                />
                <p className='text-xs leading-6'>
                  By signing/typing your name below, you agree to the Barstool Sports{' '}
                  <Link href='https://www.barstoolsports.com/content-policy'>
                    <a className='text-[#1A78BD]' target='_blank' rel='noreferrer'>
                      Content Submission Policy
                    </a>
                  </Link>
                  ,{' '}
                  <Link href='https://www.barstoolsports.com/terms-of-use'>
                    <a className='text-[#1A78BD]' target='_blank' rel='noreferrer'>
                      Terms of Service
                    </a>
                  </Link>
                  , and{' '}
                  <Link href='https://www.barstoolsports.com/privacy-policy'>
                    <a className='text-[#1A78BD]' target='_blank' rel='noreferrer'>
                      Privacy Policy
                    </a>
                  </Link>
                  , and you certify that all of the information submitted to Barstool Sports through
                  this electronic form is true and accurate.
                </p>
                <TextField name='signature' label='Signature' />
                <Button loading={submitting} type='submit'>
                  Submit
                </Button>
                {submitFailed && errors && Object.keys(errors).length > 0 && (
                  <div className='w-full p-4 text-sm text-center text-red-800 bg-red-100 border border-red-800 rounded'>
                    <p>Could not complete submission. Please fill out all required fields.</p>
                  </div>
                )}
              </form>
            )}
          </Form>
        </>
      ) : (
        <>
          <h1 className='my-6 text-3xl font-medium uppercase'>Thank you for submitting</h1>
          <p>Your video submission is pending review</p>
        </>
      )}
    </div>
  )
}

export default VideoSubmissionForm
